const packagesAllPrograms = [
    {
        name: "Paket Mini",
        id: 5682,
        channels: [],
        features: [
            "1 naprava / 1 zaslon hkrati",
            "Priporočilni sistem vsebin",
            "Aplikacija Smart TV",
            "Mobilna TV aplikacija tv2go MINI",
            "Delovanje v vseh državah EU",
        ],
    },
    {
        name: "Paket Midi",
        id: 5766,
        channels: [
            "SLO 1 (HD)",
            "SLO 2 (HD)",
            "Kanal A (HD)",
            "POP TV (HD)",
            "STAR (HD)",
            "Planet TV (HD)",
            "Astra (HD)",
            "BRIO (HD)",
            "KINO (HD)",
            "OTO (HD)",
            "T-2 Info (HD)",
            "Net TV (HD)",
            "RTS Maribor (HD)",
            "TV AS (HD)",
            "Exodus (HD)",
            "VTV Velenje (HD)",
            "SLO 3 (HD)",
            "Animal Planet (HD)",
            "BBC Earth (HD)",
            "Crime & Investigation Channel",
            "Da Vinci (HD)",
            "English Club TV (HD)",
            "National Geographic (HD)",
            "National Geographic Wild (HD)",
            "Travel Channel (HD)",
            "Viasat Explore (HD)",
            "Viasat History (HD)",
            "Viasat Nature (HD)",
            "Šport TV (HD)",
            "Arena Sport 1 Premium (HD)",
            "Arena Sport 1 (HD)",
            "Arena Sport 2 (HD)",
            "Eurosport (HD)",
            "Extreme Sports",
            "CineStar TV 1 (HD)",
            "Cinestar Action & Thriller (HD)",
            "Cinestar Fantasy (HD)",
            "Epic Drama (HD)",
            "STAR Life (HD)",
            "Klasik",
            "TV1000",
            "Baby TV (HD)",
            "Disney Channel",
            "Jim Jam",
            "Balkanika Music TV",
            "BN Music",
            "CMC - Croatian Music Channel",
            "Klape i tambure (HD)",
            "Mezzo",
            "Harmonik TV (HD)",
            "Adria (HD)",
            "One Adria (HD)",
            "Folx Slovenija (HD)",
            "Prva TV (HD)",
            "BBC News (HD)",
            "Euronews (HD)",
            "TgCom24 (HD)",
            "France 24 English",
            "RT",
            "24Kitchen Adria (HD)",
            "Kitchen TV (HD)",
            "BBC First (HD)",
            "Mediaset Italia",
            "Fashion TV (HD)",
            "LH TV (HD)",
            "Pink SI",
            "TL Novelas Europe",
            "Hayat Folk Box",
            "Hayat 2",
            "Dim (HD)",
            "Espreso TV (HD)",
            "ATM Kranjska Gora",
            "BK TV (HD)",
            "INFO TV (HD)",
            "PETV (HD)",
            "SIP TV (HD)",
            "TV Celje (HD)",
            "TV Galeja (HD)",
            "TV IDEA (HD)",
            "TV Miklavž (HD)",
            "Megafon TV (HD)",
            "KIC TV (HD)",
            "Hayat",
            "Discovery Channel (HD)",
            "History Channel",
            "AMC",
            "TinyTeen (HD)",
            "Gea TV Plus (HD)",
            "Ljubljana TV (HD)",
            "HIT TV Plus (HD)",
            "Zdrava TV (HD)",
            "Nick Music",
            "NickToons"
        ],
        features: [
            "2 napravi / 1 zaslon hkrati",
            "Priporočilni sistem vsebin",
            "Aplikacija Smart TV",
            "Mobilna TV aplikacija tv2go MINI",
            "Delovanje v vseh državah EU",
        ],
    },
    {
        name: "Paket Maxi",
        id: 5683,
        channels: [
            "SLO 1 (HD)",
            "SLO 2 (HD)",
            "Kanal A (HD)",
            "POP TV (HD)",
            "STAR (HD)",
            "Planet TV (HD)",
            "Nova 24 TV (HD)",
            "Astra (HD)",
            "BRIO (HD)",
            "KINO (HD)",
            "OTO (HD)",
            "T-2 Info (HD)",
            "GOLD TV (HD)",
            "Planet TV 2 (HD)",
            "Nova 24 TV 2 (HD)",
            "TV 3 (HD)",
            "TV Koper (HD)",
            "TV Maribor (HD)",
            "Net TV (HD)",
            "Arena TV (HD)",
            "RTS Maribor (HD)",
            "TV 8 (HD)",
            "TV AS (HD)",
            "Exodus (HD)",
            "Vaš kanal (HD)",
            "VTV Velenje (HD)",
            "SLO 3 (HD)",
            "Animal Planet (HD)",
            "BBC Earth (HD)",
            "TV Balkan Trip (HD)",
            "Crime & Investigation Channel",
            "Da Vinci (HD)",
            "English Club TV (HD)",
            "History Channel (HD)",
            "H2 (HD)",
            "ID (HD)",
            "National Geographic (HD)",
            "National Geographic Wild (HD)",
            "TLC (HD)",
            "Travelxp (HD)",
            "Travel Channel (HD)",
            "Viasat Explore (HD)",
            "Viasat History (HD)",
            "Viasat Nature (HD)",
            "Šport TV (HD)",
            "Šport TV 2 (HD)",
            "Šport TV 3 (HD)",
            "Arena Sport 1 Premium (HD)",
            "Arena Sport 1 (HD)",
            "Arena Sport 2 (HD)",
            "Arena Sport 3 (HD)",
            "Arena Sport 4 (HD)",
            "TV Arena Esport (HD)",
            "Eurosport (HD)",
            "Eurosport 2 (HD)",
            "Extreme Sports",
            "AMC (HD)",
            "CineStar TV 1 (HD)",
            "CineStar TV 2 (HD)",
            "Cinestar Action & Thriller (HD)",
            "Cinestar Comedy (HD)",
            "Cinestar Fantasy (HD)",
            "Epic Drama (HD)",
            "STAR Crime (HD)",
            "STAR Life (HD)",
            "STAR Movies (HD)",
            "Klasik",
            "TV1000",
            "Baby TV (HD)",
            "Disney Channel",
            "Disney Junior",
            "Jim Jam",
            "Minimax",
            "RTL Kockica (HD)",
            "Balkanika Music TV",
            "BN Music",
            "CMC - Croatian Music Channel",
            "Klape i tambure (HD)",
            "Mezzo",
            "TV Toxic (HD)",
            "Harmonik TV (HD)",
            "Adria (HD)",
            "One Adria (HD)",
            "Folx Slovenija (HD)",
            "Prva TV (HD)",
            "TOP TV (HD)",
            "BBC News (HD)",
            "Bloomberg Adria (HD)",
            "Euronews (HD)",
            "TgCom24 (HD)",
            "France 24 English",
            "RT",
            "24Kitchen Adria (HD)",
            "Kitchen TV (HD)",
            "BBC First (HD)",
            "Mediaset Italia",
            "Fashion TV (HD)",
            "HGTV (HD)",
            "LH TV (HD)",
            "Pink SI",
            "RTL 2 HR (HD)",
            "RTL Living (HD)",
            "RTL Televizija (HD)",
            "TL Novelas Europe",
            "Hayat Folk Box",
            "Hayat 2",
            "Dim (HD)",
            "Espreso TV (HD)",
            "TV Nakupi (HD)",
            "ATM Kranjska Gora",
            "BK TV (HD)",
            "INFO TV (HD)",
            "Koroška TV (HD)",
            "PETV (HD)",
            "R Kanal+ (HD)",
            "SIP TV (HD)",
            "Tržič TV (HD)",
            "TV Celje (HD)",
            "TV Galeja (HD)",
            "TV IDEA (HD)",
            "TV Miklavž (HD)",
            "TV Oron (HD)",
            "Megafon TV (HD)",
            "KIC TV (HD)",
            "Hayat",
            "ETV (HD)",
            "Discovery Channel (HD)",
            "History Channel",
            "Sci Fi (HD)",
            "Travelxp (4K)",
            "Agro TV (HD)",
            "AMC",
            "Arte (HD)",
            "E! (HD)",
            "Festival (4K)",
            "Funbox (4K)",
            "TinyTeen (HD)",
            "Hayat Stil i život (HD)",
            "Gea TV Plus (HD)",
            "Hayat Love box (HD)",
            "Ljubljana TV (HD)",
            "HIT TV Plus (HD)",
            "Viasat True Crime (HD)",
            "Zdrava TV (HD)",
            "Nick Music",
            "NickToons"
        ],
        features: [
            "5 naprav / 2 zaslona hkrati",
            "Priporočilni sistem vsebin",
            "Aplikacija Smart TV",
            "Mobilna TV aplikacija tv2go",
            "Delovanje v vseh državah EU",
        ],
    },
    /* {
               name: "Po meri",
               channels: []
           },*/
];

export default packagesAllPrograms;
